const marketingBroadcastQueryProps = {
  methods: {
    async loadProps() {
      if (this.commercial_type_id && /^\d+$/.test(this.commercial_type_id)) {
        this.selects.commercial = this.commercialType.find((el) => el.id === +this.commercial_type_id);
      }
      if (this.block_type_id && /^\d+$/.test(this.block_type_id)) {
        this.selects.block = this.blockTypes.find((el) => el.id === +this.block_type_id);
      }

      if (this.measurement_id && /^\d+$/.test(this.measurement_id)) {
        this.selects.measurements = this.measurementsList.find((el) => el.id === +this.measurement_id);
        if (this.selects.measurements) {
          await this.$store.dispatch('GET_TARGET', {
            'filter[measurement_company_id]': this.selects.measurements.id,
          });
          if (this.target_id && /^\d+$/.test(this.target_id)) {
            this.selects.target = this.targetList.find((el) => el.id === +this.target_id);
          }
        }
      }

      if (this.date && /\d{4}-\d{2}-\d{2}/.test(this.date)) {
        this.selects.day = this.date;
        this.lastUsed = 'day';
      }
      if (this.channel_id && /^\d+$/.test(this.channel_id)) {
        this.selects.channels = this.channel.find((el) => el.id === +this.channel_id);
      }
    },

    // Update query params after selects change
    updateQuery() {
      setTimeout(() => {
        const date = this.lastUsed === 'day' ? this.selects.day : this.firstDay;
        if (
          this.$route.query.date != date ||
          this.$route.query.channel_id != this.selects.channels?.id ||
          this.$route.query.measurement_id != this.selects.measurements?.id ||
          this.$route.query.target_id != this.selects.target?.id ||
          this.$route.query.commercial_type_id != this.selects.commercial?.id ||
          this.$route.query.block_type_id != this.selects.block?.id
        ) {
          this.$router.replace({
            query: {
              ...this.$route.query,
              date: date,
              channel_id: this.selects.channels.id,
              measurement_id: this.selects.measurements?.id,
              target_id: this.selects.target?.id,
              commercial_type_id: this.selects.commercial?.id,
              block_type_id: this.selects.block?.id,
            },
          });
        }
      }, 50);
    },
  },
};

export default marketingBroadcastQueryProps;
