<template>
  <div class="inner-broadcast marketing-grid-page">
    <SpinnerLoader :loading="isPartialUpdate" :float-in-corner="true" />
    <div class="broadcast-left" :class="[isThemeHeader === 'true' ? 'form-con__dark' : 'form-con', isSelectChannel ? 'w-0' : '']">
      <transition name="fade" mode="out-in">
        <div v-if="marketingStatus === 'loading'" class="wrapper-broadcast__loader" :class="isThemeHeader === 'true' ? 'form-con__dark' : 'form-con'">
          <SpinnerLoader :loading="marketingStatus" />
        </div>
      </transition>
      <div v-if="marketingStatus === 'success' && !isSelectChannel" class="table table-grid">
        <div class="table-header">
          <div class="table-header-text table-header-text-broadcast d-flex" style="flex-basis: 15%; padding-left: 5px; align-items: center">
            <button class="btn-transparent mr-2" @click="getMarketingInfo('prev', true)">
              <b-icon icon="arrow-left-square"></b-icon>
            </button>
            <button class="btn-transparent" @click="getMarketingInfo('next', true)">
              <b-icon icon="arrow-right-square"></b-icon>
            </button>
          </div>
          <div v-for="(colDate, index) in Object.keys(gridWithEmpty)" :key="index" class="table-header-text table-header-text-broadcast">
            {{ weekDays[new Date(colDate).getUTCDay()] }} {{ colDate | convertDate }}
            <b-icon v-if="gridBroadcastingSettings[index].is_closed === 3" class="danger-color" icon="lock" :title="$t('alert.closeDay')"></b-icon>
            <div class="text-time-seconds">{{ Object.entries(countUsedTime)[index][1].total }}/{{ Object.entries(countUsedTime)[index][1].used }}</div>
          </div>
        </div>

        <div id="table-broadcast-grid" class="table-body table-body-broadcast">
          <table border class="w-100">
            <tr>
              <td style="width: 4%" :class="isThemeHeader === 'true' ? 'white-color' : 'text-color'">
                <div id="time">
                  <template v-for="hour in scaleHour">
                    <div v-for="j in [0, 1, 2, 3, 4, 5]" :key="hour + '_' + j" class="time-hour" :style="{ height: max_scale / 6 + 'px' }">
                      {{ j === 0 ? String(hour).slice(0, 5) : '' }}
                    </div>
                  </template>
                </div>
              </td>

              <td
                v-for="(day, dayIndex) in gridWithEmpty"
                :key="dayIndex"
                :class="{ 'closed-day': checkIfDayClosedInMarketing(dayIndex) }"
                style="width: 13.71%"
                class="position-relative"
              >
                <div
                  v-for="(program, programIndex) in day"
                  :key="dayIndex + '_' + programIndex"
                  class="program"
                  :class="program.program_release_id && program.program_release_start_interval ? '' : 'independent-block'"
                  :style="[
                    program.marginTop ? { marginTop: `${program.marginTop}px` } : '',
                    program.paddingBottom ? { paddingBottom: `${program.paddingBottom}px` } : '',
                  ]"
                >
                  <div v-if="program.program_release_id && program.program_release_start_interval" class="program_header">
                    {{ sec2time(program.program_release_start_interval) + ' - ' + sec2time(program.program_release_end_interval) }}
                    <b>{{ program.program_release_program_name }}</b> {{ program.program_release_name ? ' — ' + program.program_release_name : '' }}
                  </div>

                  <div
                    v-for="(block, blockIndex) in program.blocks"
                    :key="dayIndex + '_' + programIndex + '_' + blockIndex"
                    class="block user-select-no"
                    :class="[
                      setBlockBackground(block.block_duration, block.spot_total_duration),
                      selectedBlocks.find((b) => b.block_id === block.block_id)
                        ? 'border-dark border-right border-left border-w-2px selected-marketing-block'
                        : '',
                    ]"
                    :style="block.marginTop ? { marginTop: `${block.marginTop}px` } : ''"
                    @click.prevent.shift.exact="onShiftBlockSelect($event, block, dayIndex, programIndex, blockIndex, grpByDays[dayIndex])"
                  >
                    {{ sec2time(block.block_start_interval) }}
                    <div class="positions start">
                      <div :class="{ spot_busy: block.spot_positions.includes('1F') }"></div>
                      <div :class="{ spot_busy: block.spot_positions.includes('2F') }"></div>
                      <div :class="{ spot_busy: block.spot_positions.includes('3F') }"></div>
                    </div>
                    <p class="text-center mb-0 font-weight-bolder">{{ block.block_duration }}/{{ showBlockSecondParam(block) }}</p>
                    <p>
                      <input
                        :id="block.block_id"
                        :value="getBlockRating(block.grps, grpByDays[dayIndex], selects.measurements, selects.target)"
                        class="rating-input"
                        step="any"
                        type="number"
                        :disabled="!(selects.measurements && selects.target && canEditPlan) || checkIfDayClosedInMarketing(dayIndex)"
                        @input="
                          selectedBlocks.find((b) => b.block_id === block.block_id)
                            ? changeSeveralRatings(block.block_id, $event, grpByDays[dayIndex])
                            : undefined
                        "
                        @keyup.enter="
                          selectedBlocks.find((b) => b.block_id === block.block_id)
                            ? massGRPEdit($event)
                            : updateBlockRating(
                                block.block_id,
                                dayIndex,
                                programIndex,
                                blockIndex,
                                block.grps.find((el) => el.target_audience_id === selects.target.id),
                                grpByDays[dayIndex],
                                $event
                              )
                        "
                      />
                      /{{ getBlockFactRating(block.fact_grps, selects.measurements, selects.target) }}

                      <span>
                        {{
                          block.block_commercial_type_id === 1
                            ? 'CA'
                            : block.block_commercial_type_id === 2
                            ? 'SC'
                            : block.block_commercial_type_id === 3
                            ? 'RC'
                            : ''
                        }}
                      </span>
                    </p>
                    <div class="positions end">
                      <div :class="{ spot_busy: block.spot_positions.includes('3L') }"></div>
                      <div :class="{ spot_busy: block.spot_positions.includes('2L') }"></div>
                      <div :class="{ spot_busy: block.spot_positions.includes('1L') }"></div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div
      v-if="isSelectChannel"
      class="broadcast-left d-flex justify-content-center align-items-center"
      :class="isThemeHeader === 'true' ? 'form-con__dark' : 'form-con'"
    >
      <div style="font-size: 18px" :class="isThemeHeader === 'true' ? 'white-color' : 'dark-color'">{{ $t('broadcastSideBar.selectChannel') }}</div>
    </div>

    <div class="broadcast-right d-flex flex-column" :class="isThemeHeader === 'true' ? 'form-con__dark' : 'form-con '">
      <div class="mt-2">
        <MultiSelect
          v-model="selects.channels"
          class="mb-2 multiselect-sm"
          :options="channel"
          :placeholder="$t('channelModal.channel')"
          label="name"
          track-by="id"
          :allow-empty="false"
        ></MultiSelect>

        <template v-if="!isSelectChannel">
          <datepicker-wrapper id="edit-program-datepicker-buttons-from_123" v-model="selects.day" class="mb-2" size="sm" dropleft required />

          <MultiSelect
            v-model="selects.measurements"
            class="mb-2 multiselect-sm"
            :options="measurementsList"
            :placeholder="$t('channelModal.measurements')"
            label="name"
            track-by="id"
            @input="updateQuery()"
          ></MultiSelect>

          <MultiSelect
            v-model="selects.target"
            class="mb-2 multiselect-sm"
            :options="targetList"
            :placeholder="$t('channelModal.target')"
            label="name"
            track-by="id"
            @input="updateQuery()"
          ></MultiSelect>

          <MultiSelect
            v-model="selects.commercial"
            class="mb-1 multiselect-sm"
            :options="commercialType"
            :allow-empty="false"
            :placeholder="$t('table.selectCommercialType')"
            label="name"
            track-by="id"
          ></MultiSelect>

          <!-- <MultiSelect
            v-model="selects.block"
            class="mb-1 multiselect-sm"
            :options="blockTypes"
            :allow-empty="false"
            :placeholder="$t('table.selectBlockType')"
            label="name"
            track-by="id"
          ></MultiSelect> -->

          <BlockSecondParamSelector v-model="secondBlockParam" />
        </template>
      </div>
      <div v-if="!isSelectChannel" class="grid-wrapper__btn marketing-sidebar-btns align-items-center mb-2">
        <template v-if="canUploadPlan">
          <b-button v-b-modal.import-ratings-modal size="sm">
            {{ $t('broadcastSideBar.importRating') }}
          </b-button>
          <ModalImportPlanRatings
            :channel-id="selects.channels ? selects.channels.id : undefined"
            @updateData="getMarketingInfo(lastUsed === 'day' ? 'day' : 'update')"
          />
        </template>

        <template v-if="canCopyPlan">
          <b-button v-b-modal.copy-ratings-modal size="sm">
            {{ $t('broadcastSideBar.copyRating') }}
          </b-button>
          <ModalCopyRatings
            :channel-id="selects.channels ? selects.channels.id : undefined"
            @updateData="getMarketingInfo(lastUsed === 'day' ? 'day' : 'update')"
          />
        </template>

        <template v-if="canUploadFact">
          <b-button v-b-modal.import-fact-ratings-modal size="sm">
            {{ $t('broadcastSideBar.uploadFact') }}
          </b-button>
          <ModalImportFactRatings :channel-id="selects.channels ? selects.channels.id : undefined" :selected-day="selects.day" />
        </template>

        <router-link
          v-if="canViewMatchingFactPage"
          :to="{
            name: 'matchingFactImport',
            query: {
              date: lastUsed === 'day' ? selects.day : firstDay,
              channel_id: selects.channels ? selects.channels.id : undefined,
            },
          }"
          class="btn btn-outline-danger btn-sm"
        >
          {{ $t('table.matchingImport') }}
        </router-link>
      </div>

      <div class="grid-wrapper__btn py-2 mt-auto">
        <b-button v-if="canExportMarketing" size="sm" :disabled="exporting || isSelectChannel" @click="exportReport">
          {{ exporting ? $t('table.exporting') : $t('table.export') }}
        </b-button>
        <b-button size="sm" @click="$router.go(-1)">
          {{ $t('broadcastSideBar.exit') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MultiSelect from '@/components/MultiSelect';
import errorsHandler from '@/utils/errorsHandler';
import SpinnerLoader from '../components/SpinnerLoader';
import convertDate from '../filters/convertDate';
import placementGrid from '../mixins/placementGrid';
import marketingBroadcastQueryProps from '../mixins/marketingBroadcastQueryProps';
import downloadFileHandler from '@/mixins/downloadFileHandler';
import getBlockRatings from '@/mixins/getBlockRatings';
import DatepickerWrapper from '@/components/DatepickerWrapper.vue';
import showBlockSecondParam from '@/mixins/showBlockSecondParam';

export default {
  name: 'MarketingGrid',
  components: {
    SpinnerLoader,
    MultiSelect,
    ModalImportPlanRatings: () => import('@/components/Marketing/ModalImportPlanRatings'),
    ModalImportFactRatings: () => import('@/components/Marketing/ModalImportFactRatings'),
    ModalCopyRatings: () => import('@/components/Marketing/ModalCopyRatings'),
    DatepickerWrapper,
    BlockSecondParamSelector: () => import('@/components/BlockSecondParamSelector'),
  },
  filters: {
    convertDate,
  },
  mixins: [placementGrid, marketingBroadcastQueryProps, downloadFileHandler, getBlockRatings, showBlockSecondParam],
  props: {
    channel_id: { type: [String, Number], default: undefined },
    date: { type: [String, Number], default: undefined },
    measurement_id: { type: [String, Number], default: undefined },
    target_id: { type: [String, Number], default: undefined },
    commercial_type_id: { type: [String, Number], default: undefined },
    block_type_id: { type: [String, Number], default: undefined },
  },
  data() {
    return {
      lastDay: '',
      isSelectChannel: true,
      selects: {
        commercial: '',
        block: '',
        channels: '',
        measurements: '',
        target: '',
        day: '',
        agency: '',
        advertiser: '',
        project: '',
        order: '',
        mediaPlan: '',
      },
      currentBlock: '',
      lastUsed: '',
      exporting: false,
      isPartialUpdate: '',
      selectedBlocks: [],
    };
  },
  validations: {},
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      marketingGrid: 'getMarketing',
      marketingStatus: 'getMarketingStatus',
      isThemeHeader: 'isTheme',
      target: 'getTarget',
      channel: 'getChannel',
      measurementsList: 'getMeasurementsList',
      targetList: 'getTargetList',
      commercialType: 'getCommercialType',
      blockTypes: 'getBlockTypes',
    }),
    canEditPlan: function () {
      return this.$checkPermissions('grp_plan.update_for_block');
    },
    canUploadPlan() {
      return this.$checkPermissions('grp_plan.upload');
    },
    canCopyPlan() {
      return this.$checkPermissions('grp_plan.copy');
    },
    canUploadFact() {
      return this.$checkPermissions('grp_fact.upload');
    },
    canViewMatchingFactPage() {
      return this.$checkPermissions('grp_fact.bind', 'grp_fact.unbind');
    },
    canExportMarketing() {
      return this.$checkPermissions('export.channel_marketing');
    },
  },
  watch: {
    // Filters
    async 'selects.measurements'() {
      if (!this.isSelectChannel) {
        this.selects.target = '';
        if (this.selects.measurements && this.marketingStatus === 'success') {
          await this.$store.dispatch('GET_TARGET', {
            'filter[measurement_company_id]': this.selects.measurements.id,
          });
        }
      }
    },
    async 'selects.channels'() {
      if (this.selects.channels) {
        this.count = 0;
        await this.getMarketingInfo(this.lastUsed === 'day' ? 'day' : 'update', true);
      }
    },
    marketingStatus() {
      if (this.marketingStatus === 'success') {
        this.isSelectChannel = false;
      }
    },
    async 'selects.day'() {
      if (!this.isSelectChannel) {
        await this.getMarketingInfo('day', true);
        this.lastUsed = 'day';
      }
    },

    async 'selects.commercial'() {
      if (!this.isSelectChannel) {
        await this.getMarketingInfo(this.lastUsed === 'day' ? 'day' : 'update', true);
      }
    },

    async 'selects.block'() {
      if (!this.isSelectChannel) {
        await this.getMarketingInfo(this.lastUsed === 'day' ? 'day' : 'update', true);
      }
    },
  },
  created() {
    document.title = this.$i18n.t('table.marketing') + ' – OpenMediaLogic';
  },
  mounted() {
    this.getSelectInfo();
  },
  updated() {
    this.$nextTick(function () {
      if (this.marketingStatus === 'success' && !this.isSelectChannel && this.posY >= 10) {
        // restore scroll pos after some action
        const broadcastTable = document.getElementById('table-broadcast-grid');
        const TABLE_Y_POS = broadcastTable.getBoundingClientRect().top;
        broadcastTable.scrollTo({ top: this.posY - this.clientY + TABLE_Y_POS });
        this.posY = 0;
        this.clientY = 0;
      }
    });
  },
  destroyed() {
    this.$store.commit('clearTargetsList');
    this.$store.commit('clearMarketing');
  },
  methods: {
    async getMarketingInfo(date, loader) {
      //clear multiselect on grid reload
      this.selectedBlocks = [];
      if (!loader) this.isPartialUpdate = 'loading';
      this.setCountAndDates(date);
      if (loader) this.$store.commit('getMarketingRequest');
      await this.$store.dispatch('GET_MARKETING', {
        data: {
          channel: this.selects.channels.id,
        },
        date: {
          date_start_at: date === 'day' ? this.firstDays : this.firstDay,
          date_end_at: date === 'day' ? this.lastDays : this.lastDay,
          commercial_type_id: this.selects.commercial.id || null,
          block_type_id: this.selects.block.id || null,
        },
      });
      await this.buildGrid();
      this.updateQuery();
      this.isPartialUpdate = '';
    },
    async getSelectInfo() {
      await Promise.all([
        this.measurementsList.length < 1 ? this.$store.dispatch('GET_MEASUREMENTS', { per_page: 1000 }) : undefined,
        this.channel.length < 1 ? this.$store.dispatch('GET_CHANNEL', { per_page: 1000 }) : undefined,
        this.commercialType.length < 1 ? this.$store.dispatch('GET_COMMERCIAL_TYPE') : undefined,
        this.blockTypes.length < 1 ? this.$store.dispatch('GET_BLOCK_TYPES') : undefined,
      ]);
      this.loadProps();
    },

    setBlockBackground(duration, used) {
      if (used) {
        const percent = used / duration;
        if (percent >= 0.5 && percent < 0.9) {
          return 'block-bg-yellow';
        } else if (percent >= 0.9) {
          return 'block-bg-red';
        } else {
          return '';
        }
      } else {
        return '';
      }
    },

    async updateBlockRating(block_id, dayIndex, programIndex, blockIndex, rating, minRating, event, isFromMassEdit) {
      const grpId = rating ? rating.id : null;
      const price = +event.target.value;
      const minChannelRating = +minRating ?? 0;
      let formData = {};
      if (price >= minChannelRating) {
        formData = {
          block_id: block_id,
          grp: price,
          measurement_company_id: this.selects.measurements.id,
          target_audience_id: this.selects.target.id,
        };
        // update existing GRP if ID exists
        if (grpId) formData.id = grpId;
        await this.$store.dispatch('PUT_BLOCK_GRP', {
          formData,
          handler: (res) => {
            this.$notify({
              type: 'success',
              title: this.$i18n.t('alert.blockRatingUpdated'),
            });
            //update if rating exists
            if (grpId) {
              const currentRating = this.gridWithEmpty[dayIndex][programIndex].blocks[blockIndex].grps.find((el) => el.id === grpId);
              currentRating.grp = res.data.data.grp;
            } else {
              //put new ratings response into data
              this.gridWithEmpty[dayIndex][programIndex].blocks[blockIndex].grps.push(res.data.data);
            }
            if (isFromMassEdit) this.selectedBlocks = this.selectedBlocks.filter((e) => e.block_id !== block_id);
          },
          handlerError: (errors) => {
            errorsHandler(errors, this.$notify);
          },
        });
      } else {
        //show error if block rating is less than default channel one
        this.$notify({
          type: 'error',
          title: this.$i18n.t('alert.fixRating'),
          text: this.$i18n.t('alert.ratingIsLowerThanDefault') + ' (' + minChannelRating + ')',
          duration: 3000,
        });
      }
    },

    async exportReport() {
      this.exporting = true;
      await this.$store.dispatch('GET_EXPORT_MARKETING', {
        id: this.selects.channels.id,
        params: {
          format: 'xlsx',
          date_start_at: this.lastUsed === 'day' ? this.firstDays : this.firstDay,
          commercial_type_id: this.selects.commercial ? this.selects.commercial.id : null,
          measurement_company_id: this.selects.measurements ? this.selects.measurements.id : null,
          target_audience_id: this.selects.target ? this.selects.target.id : null,
        },
        handler: (res) => {
          this.prepareAndDownloadFile(res);
        },
      });
      this.exporting = false;
    },

    onShiftBlockSelect(event, block, dayIndex, programIndex, blockIndex, grpByDay) {
      // don't allow selecting block in closed day
      if (this.checkIfDayClosedInMarketing(dayIndex)) return;
      // add block if wasn't in selection. Else -> remove from it
      if (this.selectedBlocks.find((b) => b.block_id === block.block_id))
        this.selectedBlocks = this.selectedBlocks.filter((b) => b.block_id !== block.block_id);
      else {
        // push all needed fields for later mass grp edit
        const modifiedBlock = {
          ...block,
          dayIndex,
          programIndex,
          blockIndex,
          grpByDay,
        };
        this.selectedBlocks.push(modifiedBlock);
      }
    },

    changeSeveralRatings(currentInputId, event, minRating) {
      const price = event.target.value;
      this.selectedBlocks.forEach((e) => {
        if (currentInputId !== e.block_id && document.getElementById(e.block_id)) document.getElementById(e.block_id).value = price;
      });
    },

    massGRPEdit(event) {
      const queue = this.selectedBlocks;
      queue.forEach((e) => {
        this.updateBlockRating(
          e.block_id,
          e.dayIndex,
          e.programIndex,
          e.blockIndex,
          e.grps.find((el) => el.target_audience_id === this.selects.target.id),
          e.grpByDay,
          event,
          true
        );
      });
    },
  },
};
</script>

<style lang="sass">
@use "~/src/assets/sass/broadcast.sass"

.marketing-grid-page
  .table-body-broadcast
    height: calc(100vh - 109px)

  .rating-input
    width: 30px
    padding: 0
    &:disabled
      background-color: transparent !important
      border: 0

  /* Chrome, Safari, Edge, Opera */
  input.rating-input::-webkit-outer-spin-button,
  input.rating-input::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0

  /* Firefox */
  input[type=number].rating-input
    -moz-appearance: textfield

  .selected-marketing-block
    filter: brightness(0.9)
    input
      filter: brightness(1.1)

[data-color-scheme="dark"]
  .marketing-grid-page
    .rating-input:not(:disabled)
      background-color: white !important
      color: #4b4b5a !important
      border: 1px solid hsl(222, 14%, 70%) !important
      border-style: inset !important
</style>
